import gql from 'graphql-tag';

export const addAdminScreenVersion = gql`
  mutation CreateAdminScreenVersionInput(
    $input: CreateAdminScreenVersionInput!
  ) {
    createAdminScreenVersion(input: $input) {
      id
      adminScreenID
      updatedAt
      name
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
    }
  }
`;

export const updateAdminScreenVersion = gql`
  mutation UpdateAdminScreenVersionInput(
    $input: UpdateAdminScreenVersionInput!
  ) {
    updateAdminScreenVersion(input: $input) {
      id
      adminScreenID
      updatedAt
      name
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
    }
  }
`;

export const deleteAdminScreenVersion = gql`
  mutation DeleteAdminScreenVersionInput(
    $input: DeleteAdminScreenVersionInput!
  ) {
    deleteAdminScreenVersion(input: $input) {
      id
      adminScreenID
      updatedAt
      name
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
    }
  }
`;

export const addAdminScreen = gql`
  mutation CreateAdminScreenInput( $input: CreateAdminScreenInput! ) {
    createAdminScreen(input: $input) {
      id
      updatedAt
    }
  }
`;

export const addScreen = gql`
  mutation CreateScreenInput( $input: CreateScreenInput! ) {
    createScreen(input: $input) {
      type
      updatedAt
    }
  }
`;

export const createAccountDeletionRequest = /* GraphQL */ `
mutation CreateAccountDeletionRequest(
  $input: CreateAccountDeletionRequestInput!
  $condition: ModelAccountDeletionRequestConditionInput
) {
  createAccountDeletionRequest(input: $input, condition: $condition) {
    id
    updatedAt
    cognitoStatus
    createdAt
    user
  }
}
`;

export const addCuratedMix = gql`
  mutation CreateCuratedMixInput(
    $input: CreateCuratedMixInput!
  ) {
    createCuratedMix(input: $input) {
      id
      title
      description
      markets
      createdAt
      updatedAt
    }
  }
`;

export const updateCuratedMix = gql`
  mutation UpdateCuratedMixInput(
    $input: UpdateCuratedMixInput!
  ) {
    updateCuratedMix(input: $input) {
      id
      title
      description
      markets
      createdAt
      updatedAt
    }
  }
`;

export const addCuratedMixEpisode = gql`
  mutation CreateCuratedMixEpisodeInput(
    $input: CreateCuratedMixEpisodeInput!
  ) {
    createCuratedMixEpisode(input: $input) {
      id
      curatedMixId
      episodeId
      createdAt
      updatedAt
    }
  }
`;

export const deleteCuratedMix = gql`
  mutation DeleteCuratedMixInput(
    $input: DeleteCuratedMixInput!
    $condition: ModelCuratedMixConditionInput
  ) {
    deleteCuratedMix(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCuratedMixEpisode = gql`
  mutation DeleteCuratedMixEpisodeInput(
    $input: DeleteCuratedMixEpisodeInput!
    $condition: ModelCuratedMixEpisodeConditionInput
  ) {
    deleteCuratedMixEpisode(input: $input, condition: $condition) {
      id
      curatedMixId
      episodeId
    }
  }
`;

export const deleteCuratedMixSeries = gql`
  mutation DeleteCuratedMixSeriesInput(
    $input: DeleteCuratedMixSeriesInput!
    $condition: ModelCuratedMixSeriesConditionInput
  ) {
    deleteCuratedMixSeries(input: $input, condition: $condition) {
      id
      curatedMixId
      seriesId
    }
  }
`;